import React, { Component } from "react";

export default class Hours extends Component {

    isThanksgivingWeek() {
        let today = new Date();
        let fridayBefore = new Date(2025, 10, 25);
        let fridayAfter = new Date(2025, 10, 29);
        return fridayBefore <= today && today < fridayAfter;

    }

    isClosedForTheSeason() {
        let today = new Date();
        let lastDayOpen = new Date(2024, 9, 27);
        let mondayBefore = new Date(2025, 1, 12);
        return lastDayOpen <= today && today < mondayBefore;

    }

    renderClosedForSeasonHours() {
        const listStyle = { border: "none" }
        return (
            <>
                <li className="list-group-item" style={listStyle}>Closed for the Season</li>
                <li className="list-group-item" style={listStyle}>Re-Open</li>
                {/*<li className="list-group-item" style={listStyle}>Thursday, Feb 15th, 2024</li>*/}
                <li className="list-group-item" style={listStyle}>February 2025</li>
            </>
        )
    }

    renderSeasonHours() {
        const listStyle = { border: "none" }
        return (
            <>
                <li className="list-group-item" style={listStyle}>Sunday: 4:00pm - 9:00pm</li>
                <li className="list-group-item" style={listStyle}>Monday: Closed</li>
                <li className="list-group-item" style={listStyle}>Tuesday: Closed</li>
                { this.isThanksgivingWeek() ?
                    <>
                        <li className="list-group-item" style={listStyle}>Wednesday: 4:00pm - 9:00pm</li> 
                        <li className="list-group-item" style={listStyle}>Thanksgiving Day: Closed</li>
                    </>
                :
                    <>
                        <li className="list-group-item" style={listStyle}>Wednesday: Closed</li>
                        <li className="list-group-item" style={listStyle}>Thursday 4:00pm - 9:00pm</li>
                    </>
                    }
                <li className="list-group-item" style={listStyle}>Friday: 4:00pm - 10:00pm</li>
                <li className="list-group-item" style={listStyle}>Saturday: 4:00pm - 10:00pm</li>
            </>
        )
    }

    
    render() {

        return (
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h4 className="text-center">Hours</h4>
                            <ul className="list-group text-center">
                                { this.isClosedForTheSeason() ? this.renderClosedForSeasonHours() : this.renderSeasonHours() }
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
